import Vue from 'vue'
import axios from 'axios'
import jsSHA from 'jssha'
import {
  VueAxios
} from './axios'
import {
  ACCESS_TOKEN,
  USERID
} from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: '/admin', // api base_url
  timeout: 6000
})

const err = (error) => {
  // if (error.response) {
  //   const data = error.response.data
  //   const token = Vue.ls.get(ACCESS_TOKEN)
  //   if (error.response.status === 403) {
  //     notification.error({
  //       message: '禁止访问',
  //       description: data.message
  //     })
  //   }
  //   if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
  //     notification.error({
  //       message: '认证失败',
  //       description: '认证过期'
  //     })
  //     if (token) {
  //       store.dispatch('Logout').then(() => {
  //         setTimeout(() => {
  //           window.location.reload()
  //         }, 1500)
  //       })
  //     } else {
  //       window.location.href = '/u/login'
  //     }
  //   }
  // }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  const userid = Vue.ls.get(USERID)
  if (token) {
    config.headers['Access-Token'] = token
    config.headers['USERID'] = userid
    config.headers['client'] = 'pc'
  }
  if (!config.data) {
    config.data = {}
  }
  if (config.method === 'post' && config.data && (typeof config.data === 'object')) {
    var args = Object.assign({}, config.data)
    args.requesturl = '/admin' + config.url
    args.requesttime = (new Date()).getTime()
    args.requestnonce = Math.random().toString(36).substr(2, 15)
    config.headers['accept-language'] = window.navigator.language
    config.headers['requesttime'] = args.requesttime
    config.headers['requestnonce'] = args.requestnonce
    var keys = Object.keys(args)
    keys = keys.sort()
    var newArgs = {}
    keys.forEach(function (key) {
      newArgs[key] = args[key]
    })

    var string = ''
    for (var k in newArgs) {
      if (newArgs[k] !== undefined && newArgs[k] !== null) {
        string += '&' + k + '=' + encodeURIComponent(newArgs[k])
      }
    }
    string = string.substr(1)

    // eslint-disable-next-line new-cap
    var shaObj = new jsSHA('SHA-1', 'TEXT')
    shaObj.update(string)
    var signature = shaObj.getHash('HEX')
    config.headers['sign'] = signature
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
