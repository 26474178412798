
export default {
  'main.title': '酒精测试管理',
  'home.home': '首页',
  'user.account': '账号',
  'user.email': '邮箱',
  'user.email.tip': '请输入邮箱地址',
  'user.password': '密码',
  'user.reg': '注册',
  'user.reg.success': '注册成功',
  'user.reg.fail': '注册失败',
  'user.reg.success.description': '即将返回登录页面',
  'user.regnow': '立即注册',
  'user.login': '登录',
  'user.signout': '注销登录',
  'user.captcha': '验证码',
  'user.signInGoogle': '使用Google登录',
  'user.emailCaptcha': '邮箱验证码',
  'user.emailCaptcha.tip': '请输入邮箱验证码',
  'user.getCaptcha': '获取验证码',
  'user.readAndAgree': '我已阅读并同意',
  'user.UserServiceAgreement': '用户服务协议',
  'user.action.delete': '删除',
  'user.action.more': '更多操作',
  'menu.record': '测试记录',
  'menu.driver': '司机管理',
  'menu.title': '酒精测试管理',
  'user.accountSetting': '账户设置',
  'user.tip': '提示',
  'user.signOutTip': '真的要注销登录吗 ?',
  'table.emptyText': '暂无数据',
  'user.action': '操作',
  'user.name': '姓名',
  'user.name.tip': '请输入姓名',
  'user.bindTime': '绑定时间',
  'user.action.search': '搜索',
  'user.action.bind': '绑定司机',
  'user.action.qrWinOpenTip': '请勿在司机扫码授权完成前关闭该窗口。',
  'user.action.scanBind': '通过扫描二维码绑定司机',
  'user.action.emailBind': '通过输入管理员邮箱绑定司机',
  'user.visit.tip': '请当面邀请司机扫描该二维码。',
  'user.action.bind.success': '司机绑定成功',
  'user.test.id': '序列号',
  'user.test.value': '检测值(mg/L)',
  'user.test.date': '检测日期',
  'user.test.time': '检测时间',
  'user.id': '司机ID',
  'user.test.appraise': '评估',
  'user.test.user': '测试人',
  'user.test.image': '照片',
  'user.test.appraise.yes': 'Yes',
  'user.test.appraise.no': 'No',
  'user.car.license_plate_number': '车牌号',
  'user.action.export': '导出',
  'user.action.export.tip': '请选择要导出的记录',
  'user.action.export.success': '导出成功',
  'user.action.export.fail': '导出失败',
  'user.share.status': '共享状态',
  'user.share.status.enable': '启用',
  'user.share.status.disable': '禁用',
  'user.share.status.disable.tip': '司机禁用了您的数据查看权限，如需开启需要司机进入APP开启',
  'user.action.delete.tip': '确认删除吗？',
  'user.action.delete.yes': '是',
  'user.action.delete.no': '否',
  'user.password.change.password': '重置密码',
  'user.password.change.password.tip': '请输入密码',
  'user.password.change.passwordReview': '确认密码',
  'user.password.change.password.not.match': '两次输入密码不一致',
  'user.action.ok': '确认',
  'user.action.cancel': '取消',
  'user.password.change.ok': '保存',
  'user.password.change.cancel': '取消',
  'user.password.change.password.current': '当前密码',
  'user.password.change.password.new': '新密码',
  'user.password.change.password.new.tip': '请输入新密码',
  'user.password.change.password.current.tip': '请输入当前密码',
  'user.password.change.passwordReview.new': '确认新密码',
  'user.password.change.passwordReview.new.tip': '请再次输入相同的新密码',
  'user.password.change.password.format.error': '密码必须为字母数字混合，长度为8-20',
  'user.password.level.low': '低',
  'user.password.level.medium': '中',
  'user.password.level.high': '高',
  'user.password.level.error': '密码强度不够',
  'user.password.level.tip': ' 请至少输入 6 个字符。请不要使用容易被猜到的密码。',
  'user.account.login': '使用已有账户登录',
  'user.password.format.tip': '至少6位密码，区分大小写',
  'user.email.captcha.send': '验证码发送中..',
  'user.welcome': '欢迎',
  'user.welcome.back': '欢迎回来',
  'user.agreement.read': '请先阅读并同意用户隐私协议',
  'user.profile': '用户信息',
  'user.car.area': '地域',
  'user.car.type': '分类番号',
  'user.car.plateName': '平板名',
  'user.car.number': '序列编号',
  'user.car.color': '颜色',
  'user.car.color.GreenWhite': '白牌緑字',
  'user.car.color.WhiteGreen': '緑牌白字',
  'user.car.color.BlackYellow': '黄牌黑字',
  'user.car.color.YellowBlack': '黑牌黄字',
  'user.car.color.GreenBlue': '蓝牌绿字',
  'user.car.color.RedWhite': '白牌红字',
  'user.membership.level': '会员等级',
  'user.membership.upgrade': '升级会员',
  'user.membership.function': '功能',
  'user.membership.function.location': '显示测试地点',
  'user.membership.function.excel': 'EXCEL导出',
  'user.membership.days': '天',
  'user.membership.function.data.limit': '数据查看权限',
  'user.share.code.checking': '正在校验授权码',
  'user.share.code.params.err': '参数错误',
  'user.share.bind.tip': '绑定获取{name}的数据授权。',
  'user.share.bind': '绑定'
}
