import moment from 'moment'
import { i18nRender } from '@/locales'
const base = {}
base.install = function (Vue, options) {
  Vue.prototype.showModal = function (componentName, title, width, height, data) {
    var maxWidth = document.body.clientWidth
    var maxHeight = document.body.clientHeight
    if (width > maxWidth - 20) {
      width = maxWidth - 20
    }
    if (height > maxHeight - 20) {
      height = maxHeight - 20
    }
    this.$layer.iframe({
      content: {
        content: componentName, // 传递的组件对象
        parent: this, // 当前的vue对象
        data: data || {}// props
      },
      area: [width + 'px', height + 'px'],
      title: title,
      shadeClose: false
    })
  }
  Vue.prototype.hideModalForResult = function (res, option) {
    if (res.code === 0) {
      if (option && option.success) {
        option.success()
      }
      this.$layer.close(this.layerid)
    } else {
      this.$message.error(res.msg)
    }
  }
  Vue.prototype.saveResultAsExcel = function (res, filename) {
    if (!res) {
      return
    }
    const url = window.URL.createObjectURL(new Blob([res]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }
  Vue.prototype.formatTime = function (time) {
    var locInfo = window.localStorage.getItem('local')
    if (locInfo) {
      var locObj = JSON.parse(locInfo)
      return moment(time).add(locObj.timeoffset, 'hours').format('YYYY-MM-DD HH:mm:ss')
    }
    return time
  }
  Vue.prototype.$t = i18nRender
}

export default base
