<template>
  <div class="user-wrapper">
    <div class="content-box">
      <div style="display: inline-block;margin-right: 20px;">
        <a-button style="background-color: transparent;color:#ccc;border-color: #ccc;" class="action" @click="openChat"><i class="iconfont icon-kefu"><span style="margin-left: 4px;">カスタマーサービス</span></i></a-button>
      </div>
      <div style="display: inline-block;margin-right: 20px;">
        <a-button icon="question-circle" style="background-color: transparent;color:#ccc;border-color: #ccc;" class="action" @click="openHelp">ヘルプ</a-button>
      </div>

      <a-dropdown>
        <div class="action ant-dropdown-link user-dropdown-menu">
          <span style="color: white;">{{ nickname() }}</span>
          <a-icon type="down" style="color:white;margin-left: 4px;" />
        </div>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper" style="width: 240px !important;">
          <a-menu-item key="1">
            <a href="javascript:;" @click="showUInfo">
              <a-icon type="key"/>
              <span>{{ $t('user.accountSetting') }}</span>
            </a>
          </a-menu-item>
          <a-menu-divider/>
          <a-menu-item key="12">
            <a href="javascript:;" @click="showNameChange">
              <a-icon type="user"/>
              <span>{{ $t('user.name.change.title') }}</span>
            </a>
          </a-menu-item>
          <a-menu-divider/>
          <a-menu-item key="3">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout"/>
              <span>{{ $t('user.signout') }}</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'UserMenu',
  data () {
    return {
      role: 0
    }
  },
  components: {
    NoticeIcon
  },
  created () {
    var role = Vue.ls.get('role')
    this.role = role
    var locInfo = window.localStorage.getItem('local')
    if (locInfo) {
      var locObj = JSON.parse(locInfo)
      this.cLocal = locObj.name
    }
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['nickname']),
    handleLogout () {
      const that = this

      this.$confirm({
        title: this.$t('user.tip'),
        content: this.$t('user.signOutTip'),
        okText: this.$t('user.action.ok'),
        cancelText: this.$t('user.action.cancel'),
        onOk () {
          return that.Logout({}).then(() => {
            // clear db
            window.location.reload()
          }).catch(err => {
            that.$message.error({
              title: 'Error',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    showUInfo () {
      this.$emit('showAccountModal')
    },
    showNameChange () {
      this.$emit('showNameModal')
    },
    openChat () {
      window.open('http://chat.reflexwireless.jp/chat/1i6jl3i7m', '_blank')
    },
    openHelp () {
      window.open('https://www.reflexwireless.jp/cloud-manager/', '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
  .action{
    display: inline-block;
    border: 1px solid #999;
    border-radius: 4px;
    hight:32px !important;
    line-height: 32px;
    padding: 0;
    background-color: #444;
  }
</style>
