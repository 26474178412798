export default {
  'main.title': '飲酒運転検査',
  'home.home': 'ホーム',
  'user.account': 'アカウント',
  'user.email': 'メールアドレス',
  'user.email.tip': 'メールアドレスを入力してください',
  'user.password': 'パスワード',
  'user.reg': '登録',
  'user.reg.success': '登録が完了しました',
  'user.reg.fail': '登録に失敗しました',
  'user.reg.success.description': 'ログインページに戻ります',
  'user.regnow': '今すぐ登録',
  'user.login': 'ログイン',
  'user.signout': 'ログアウト',
  'user.captcha': 'キャプチャ',
  'user.captcha.tip': '認証コードを入力してください',
  'user.signInGoogle': 'Googleでログイン',
  'user.emailCaptcha': 'メールアドレスのキャプチャ',
  'user.emailCaptcha.tip': 'メールアドレスのキャプチャを入力してください',
  'user.getCaptcha': 'キャプチャを取得',
  'user.readAndAgree': '私は以下の条項に同意します',
  'user.UserServiceAgreement': 'ユーザーサービス契約',
  'user.action.delete': '削除',
  'user.action.more': '他の操作',
  'menu.record': 'テスト記録',
  'menu.driver': '運転手管理',
  'menu.title': '飲酒運転検査',
  'menu.daily.data': 'データ日報',
  'user.accountSetting': 'ログインパスワードの変更',
  'user.name.change.title': 'ニックネームの変更',
  'user.name.new': 'ニックネーム',
  'user.name.new.tip': '新しいニックネームを入力してください',
  'user.tip': 'に注意',
  'user.signOutTip': '本当にログアウトしますか?',
  'table.emptyText': 'データがありません',
  'user.action': '操作',
  'user.name': '氏名',
  'user.name.tip': '名前を入力してください',
  'user.bindTime': '運転手登録日時',
  'user.action.search': '検索',
  'user.action.bind': 'マネージャーQRコード',
  'user.action.qrWinOpenTip': '運転手がQRコードをスキャンするまでこのウィンドウを閉じないでください。',
  'user.action.scanBind': 'マネージャーQRコード',
  'user.action.emailBind': '管理者のメールアドレスを入力して運転手とバインド',
  'user.visit.tip': '運転手の方々に、このQRコードをアルコールセンサーアプリでスキャンし、管理者がアルコール検査結果にアクセスできるようにしてください。',
  'user.action.bind.success': '運転手とのバインドが成功しました',
  'user.test.value': '検査値（mg/L）',
  'user.test.date': '検査日',
  'user.test.time': '検査時刻',
  'user.id': '運転手ID',
  'user.test.id': 'シリアル',
  'user.test.appraise': '結果',
  'user.test.user': '被験者名',
  'user.test.image': '画像',
  'user.test.appraise.yes': '未検出',
  'user.test.appraise.no': '運転中止',
  'user.test.appraise.warn': '運転控え',
  'user.car.license_plate_number': '車両番号',
  'user.action.export': 'エクスポート',
  'user.action.export.tip': 'エクスポートする記録を選択してください',
  'user.action.export.success': 'エクスポートが成功しました',
  'user.action.export.fail': 'エクスポートに失敗しました',
  'user.share.status': '共有状態',
  'user.share.status.enable': '有効',
  'user.share.status.disable': '無効',
  'user.share.status.disable.tip': '運転手があなたのデータ閲覧権限を無効にしました。有効にするには、運転手がアプリを開いて設定を行っていただく必要があります。',
  'user.action.delete.tip': '本当に削除しますか?',
  'user.action.delete.yes': 'はい',
  'user.action.delete.no': 'いいえ',
  'user.password.tip': 'パスワードを入力してください',
  'user.password.change.password': 'パスワードの変更',
  'user.password.change.password.tip': '新しいパスワードを入力してください',
  'user.password.change.passwordReview': 'パスワードの確認',
  'user.password.change.password.not.match': 'パスワードが一致しません',
  'user.action.ok': '確認',
  'user.action.cancel': 'キャンセル',
  'user.password.change.ok': '保存',
  'user.password.change.cancel': 'キャンセル',
  'user.password.change.password.current': '現在のパスワード',
  'user.password.change.password.new': '新しいパスワード',
  'user.password.change.password.new.tip': '新しいパスワードを入力してください',
  'user.password.change.passwordReview.new': '新しいパスワードの確認',
  'user.password.change.passwordReview.new.tip': '同じ新しいパスワードをもう一度入力してください',
  'user.password.change.password.current.tip': '現在のログインパスワードを入力してください',
  'user.password.change.password.format.error': 'パスワードは英数字を混ぜて8〜20文字で入力してください',
  'user.password.level.low': '低い',
  'user.password.level.medium': '中',
  'user.password.level.high': '高い',
  'user.password.level.error': 'パスワードの強度が足りません',
  'user.password.level.tip': '少なくとも6文字以上を入力してください。予測しやすいパスワードを使用しないでください。',
  'user.account.login': '既存のアカウントでログイン',
  'user.password.format.tip': '最低6文字のパスワードを設定してください。大文字と小文字の区別があります。',
  'user.email.captcha.send': 'キャプチャの送信中…',
  'user.welcome': 'ようこそ',
  'user.welcome.back': 'お帰りなさい',
  'user.agreement.read': 'ユーザープライバシー契約を読んで同意してください',
  'user.profile': '運転手情報',
  'user.car.area': '地域名',
  'user.car.type': '分类番号',
  'user.car.plateName': 'ひらがな',
  'user.car.number': '一連指定番号',
  'user.car.color': '色',
  'user.car.color.GreenWhite': '白地に緑文字',
  'user.car.color.WhiteGreen': '緑牌に白文字',
  'user.car.color.BlackYellow': '黄牌に黑文字',
  'user.car.color.YellowBlack': '黑牌に黄文字',
  'user.car.color.GreenBlue': '蓝牌に绿文字',
  'user.car.color.RedWhite': '白牌に红文字',
  'user.membership.level': '会員ランク',
  'user.membership.upgrade': '会員のアップグレード',
  'user.membership.function': 'ファンクション',
  'user.membership.function.location': '検査場所表示',
  'user.membership.function.excel': 'EXCEL配信',
  'user.membership.function.bindNum': '関連人数の上限',
  'user.membership.days': '日間',
  'user.membership.function.data.limit': 'データ表示権限',
  'user.test.location': '検査場所',
  'user.test.video': 'ビデオ',
  'user.test.video.play': 'クリックしてビデオを再生',
  'user.test.data.new.tip': '新しいデータが検出されました。検索ボタンをクリックしてリストを更新してご覧ください。',
  'user.excel.appraise.yes': '基準値内',
  'user.excel.appraise.no': '基準値オーバー',
  'user.excel.exporting': 'データをエクスポートしています...',
  'user.bind.num': '合計{count}人のバインド',
  'user.alc.test.num': '{count}人がアルコールテストを行った',
  'user.alc.xlsx.download.tip': 'クリックして当日のデータをダウンロード',
  'user.alc.xlsx.auto.send': '自動送信設定',
  'user.share.code.checking': '認証コードを検証しています',
  'user.share.code.params.err': 'パラメータエラー',
  'user.share.bind.tip': 'バインド{name}のデータ認可を取得します。',
  'user.share.bind': 'バインド'
}
